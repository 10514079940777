import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrNewUserGroupForm'
})
export default class GtrNewUserGroupForm extends GtrSuper {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean|undefined

    data () {
      return {
        value: false,
        submitting: false,
        user_group: {
          name: null,
          description: null
        }
      }
    }

    @Watch('visible', { immediate: true })
    onVisibiltyChange (payload: any) {
      this.$data.value = payload
    }

    onClose () {
      this.$data.user_group = {
        name: null,
        description: null
      }
      const observer = (this.$refs.observerForm as any)
      observer.reset()
      this.$data.value = false
      this.$emit('close')
    }

    async submit () {
      const form = (this.$refs.newUserForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting = true
          const payload: any = {
            data: {
              name: this.$data.user_group.name,
              description: this.$data.user_group.description
            }
          }
          await this.$store.dispatch('company/createUserGroup', payload)
          Container.get(Notification).success('User Group successfully created.')
          this.$data.user_group = {
            name: null,
            description: null
          }
          this.onClose()
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting = false
        }
      }
    }
}
