import { Component, Vue, Watch } from 'vue-property-decorator'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import GtrNewUserGroupForm from './forms/new/new_user_group.form.vue'
import GtrEditUserGroupForm from './forms/edit/edit_user_group.form.vue'
import { mapState } from 'vuex'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
@Component({
  name: 'GtrAdminUserGroupsView',
  computed: {
    ...mapState('company', ['user_groups'])
  },
  components: {
    'gtr-new-user-group-form': GtrNewUserGroupForm,
    'gtr-edit-user-group-form': GtrEditUserGroupForm
  }
})
export default class GtrAdminUserGroupsView extends Vue {
  data () {
    return {
      userGroupToEdit: {},
      search: '',
      uuid: this.$route.params.uuid,
      loading: false,
      table: {
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Description', align: 'start', value: 'description' },
          { text: 'Active Users', align: 'start', value: 'active_users_count' },
          { text: 'Inactive Users', align: 'start', value: 'inactive_users_count' },
          { text: 'Total Users Count', align: 'start', value: 'users_count' },
          { text: 'Actions', value: 'actions', searchable: false, sortable: false, width: '90px' }
        ],
        items: []
      },
      forms: {
        showNewUserGroupForm: false,
        showEditUserGroupForm: false
      },
      companyToDelete: null
    }
  }

  @Watch('user_groups')
  onUSerGroupsChange (payload: any) {
    if (payload) {
      this.$data.table.items = payload.filter(group => group.active)
    }
  }

  async mounted () {
    await this.fetchUserGroups()
  }

  handleViewGroup (user_group: any) {
    this.$store.commit('company/SET_USER_GROUP', user_group)
    this.$router.push({ name: 'level-one.user_groups.edit', params: { uuid: user_group.uuid } })
  }

  async handleDeleteUserGroup (user_group_uuid: string) {
    try {
      this.$data.loading = true
      const data = { active: 0 }
      await this.$store.dispatch('company/updateUserGroup', { user_group_uuid, data })
      Container.get(Notification).success('User group successfully deleted')
      this.$data.table.items = this.$data.table.items.filter(group => group.uuid !== user_group_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  handleDeleteCompany (payload: any) {
    this.$data.companyToDelete = payload
  }

  handleShowNewUserGroupForm () {
    this.$data.forms.showNewUserGroupForm = true
  }

  handleCloseNewUserGroupForm () {
    this.$data.forms.showNewUserGroupForm = false
  }

  handleShowEditUserGroupForm (payload) {
    this.$data.forms.showEditUserGroupForm = true
    this.$data.userGroupToEdit = payload
  }

  handleCloseEditUserGroupForm () {
    this.$data.forms.showEditUserGroupForm = false
  }

  private async fetchUserGroups () {
    try {
      this.$data.loading = true
      this.$store.commit('company/SET_USER_GROUPS', [])
      await this.$store.dispatch('company/fetchUserGroups')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  handleIfAbleToEdit (item) {
    return item.name !== 'SUPER_ADMIN' &&
      item.name !== 'RESELLER_ADMIN' &&
      item.name !== 'COMPANY_ADMIN' &&
      item.name !== 'COMPANY_USER' &&
      item.name !== 'COMPANY_REPORT_USER'
  }

  handleIfAbleToDelete (item) {
    return item.users_count === 0 &&
      item.name !== 'SUPER_ADMIN' &&
      item.name !== 'RESELLER_ADMIN' &&
      item.name !== 'COMPANY_ADMIN' &&
      item.name !== 'COMPANY_USER' &&
      item.name !== 'COMPANY_REPORT_USER'
  }
}
